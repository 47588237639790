import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

  public routes: IRouteConfig[] = [
    {
      id: 'logout',
      category: 'logout',
      iconClass: null,
      displayName: null,
      displayInNav: false,
      defaultRoute: '/logout',
      roles: undefined,
      children: [],
      routeData: {
        redirectOnLogout: true,
      }
    },
    {
      id: 'leads',
      category: 'leads',
      default: true,
      defaultRoute: '/leads/overview',
      displayName: 'Leads',
      displayInNav: true,
      iconClass: 'fa fa-users',
      roles: undefined,
      children: [
        {
          parent: '/leads',
          route: 'overview',
          displayName: 'Overview',
          iconClass: 'fa fa-users',
          pdfEnabled: false,
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/leads',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/leads',
          route: 'sourcePerformance',
          displayName: 'Source',
          iconClass: 'fa fa-list',
          roles: undefined
        },
        //{
        //  parent: '/leads',
        //  route: 'vehiclePerformance',
        //  displayName: 'Vehicle',
        //  iconClass: 'fa fa-car',
        //  roles: undefined,
        //  breadcrumbHelpEnabled: true
        //},
        {
          parent: '/leads',
          route: 'transactions',
          displayName: 'Transactions',
          iconClass: 'fa fa-exchange',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        //{
        //  parent: '/leads',
        //  route: 'process-performance',
        //  displayName: 'Process Performance',
        //  iconClass: 'fa fa-exchange',
        //  roles: undefined,
        //  breadcrumbHelpEnabled: true
        //},
      ]
    },
    //{
    //  id: 'website',
    //  category: 'website',
    //  defaultRoute: '/website/overview',
    //  displayName: 'Website',
    //  displayInNav: true,
    //  iconClass: 'fa fa-globe',
    //  roles: undefined,
    //  children: [
    //    {
    //      parent: '/website',
    //      route: 'overview',
    //      displayName: 'Overview',
    //      pdfEnabled: false,
    //      breadcrumbHelpEnabled: true,
    //      iconClass: 'fa fa-users',
    //      roles: undefined
    //    }]
    //},
    //{
    // id: 'website',
    // category: 'website',
    // defaultRoute: '/website/overview',
    // displayName: 'Website',
    // displayInNav: true,
    // iconClass: 'fa fa-globe',
    // roles: undefined,
    // children: [
    //   {
    //     parent: '/website',
    //     route: 'overview',
    //     displayName: 'Overview',
    //     pdfEnabled: false,
    //     breadcrumbHelpEnabled: true,
    //     iconClass: 'fa fa-users',
    //     roles: undefined
    //   }]
    //},
    //{
    //  id: 'website',
    //  category: 'website',
    //  defaultRoute: '/website/overview',
    //  displayName: 'Website',
    //  displayInNav: true,
    //  iconClass: 'fa fa-globe',
    //  roles: undefined,
    //  children: [
    //    {
    //      parent: '/website',
    //      route: 'overview',
    //      displayName: 'Overview',
    //      pdfEnabled: false,
    //      breadcrumbHelpEnabled: true,
    //      iconClass: 'fa fa-users',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/website',
    //      route: 'scorecard',
    //      displayName: 'Scorecard',
    //      iconClass: 'fa fa-list',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/website',
    //      route: 'summary',
    //      displayName: 'Summary',
    //      iconClass: 'fa fa-list',
    //      breadcrumbHelpEnabled: true,
    //      roles: undefined
    //    },
    //    {
    //      parent: '/website',
    //      route: 'referrer',
    //      displayName: 'Referrer',
    //      iconClass: 'fa fa-share',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/website',
    //      route: 'vehicle',
    //      displayName: 'Vehicle',
    //      iconClass: 'fa fa-car',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/website',
    //      route: 'provider',
    //      displayName: 'Provider',
    //      iconClass: 'fa fa-cogs',
    //      roles: ['System Administrator', 'Corporate']
    //    },
    //  ]
    //},

    //{
    //  id: 'digAd',
    //  category: 'digAd',
    //  default: true,
    //  defaultRoute: '/digAd/dashboard',
    //  displayName: 'Digital Advertising',
    //  displayInNav: true,
    //  iconClass: 'fa fa-desktop',
    //  roles: undefined,
    //  children: [
    //    // {
    //    //   parent: '/digAd',
    //    //   route: 'overview',
    //    //   displayName: 'Overview',
    //    //   pdfEnabled: false,
    //    //   iconClass: 'fa fa-users',
    //    //   breadcrumbHelpEnabled: true,
    //    //   roles: undefined
    //    // },
    //    {
    //      parent: '/digAd',
    //      route: 'dashboard',
    //      displayName: 'Overview',
    //      pdfEnabled: false,
    //      iconClass: 'fa fa-users',
    //      breadcrumbHelpEnabled: true,
    //      roles: undefined
    //    },
    //  ]
    //},
    //{
    //  id: 'digAd',
    //  category: 'digAd',
    //  default: true,
    //  defaultRoute: '/digAd/overview',
    //  displayName: 'Digital Advertising',
    //  displayInNav: true,
    //  iconClass: 'fa fa-desktop',
    //  roles: undefined,
    //  children: [
    //    {
    //      parent: '/digAd',
    //      route: 'overview',
    //      displayName: 'Overview',
    //      pdfEnabled: false,
    //      breadcrumbHelpEnabled: true,
    //      iconClass: 'fa fa-users',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/digAd',
    //      route: 'scorecard',
    //      displayName: 'Scorecard',
    //      breadcrumbHelpEnabled: true,
    //      iconClass: 'fa fa-list',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/digAd',
    //      route: 'summary',
    //      displayName: 'Summary',
    //      breadcrumbHelpEnabled: true,
    //      iconClass: 'fa fa-list',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/digAd',
    //      route: 'provider',
    //      displayName: 'Provider',
    //      breadcrumbHelpEnabled: true,
    //      iconClass: 'fa fa-cogs',
    //      roles: undefined
    //    },
    //    {
    //      parent: '/digAd',
    //      route: 'channel',
    //      displayName: 'Channel',
    //      breadcrumbHelpEnabled: true,
    //      iconClass: 'fa fa-cogs',
    //      roles: undefined
    //    }
    //  ]
    //},
    {
      id: 'sales',
      category: 'sales',
      default: true,
      defaultRoute: '/sales/overview',
      displayName: 'Sales',
      displayInNav: true,
      iconClass: 'fa fa-usd',
      roles: undefined,
      children: [
        {
          parent: '/sales',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: false,
          iconClass: 'fa fa-users',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/sales',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        // {
        //   parent: '/sales',
        //   route: 'source',
        //   displayName: 'Source',
        //   iconClass: 'fa fa-car',
        //   roles: undefined,
        //   breadcrumbHelpEnabled: true
        // },
        {
          parent: '/sales',
          route: 'vehicle',
          displayName: 'Vehicle',
          iconClass: 'fa fa-car',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
      ]
    },
    //{
    //  id: 'provider-tools',
    //  category: 'provider-tools',
    //  default: true,
    //  defaultRoute: '/provider-tools/chat-overview',
    //  displayName: 'Provider Tools',
    //  displayInNav: true,
    //  iconClass: 'fa fa-building',
    //  roles: undefined,
    //  children: [
    //    // Digital Retailing
    //    {
    //      parent: '/provider-tools',
    //      route: 'digital-retailing',
    //      displayName: 'Digital Retailing',
    //      pdfEnabled: false,
    //      breadcrumbHelpEnabled: true,
    //      iconClass: 'fa fa-users',
    //      roles: undefined
    //    },
    //    // Trade In
    //    {
    //      parent: '/provider-tools',
    //      route: 'trade-in',
    //      displayName: 'Trade-In',
    //      pdfEnabled: true,
    //      breadcrumbHelpEnabled: true,
    //      iconClass: 'fa fa-exchange',
    //      roles: undefined
    //    },
    //    // Chat
    //    {
    //      parent: '/provider-tools',
    //      route: 'chat-overview',
    //      displayName: 'Chat',
    //      pdfEnabled: false,
    //      iconClass: 'fa fa-users',
    //      breadcrumbHelpEnabled: true,
    //      roles: undefined
    //    },
    //    // Service Scheduler
    //    {
    //      parent: '/provider-tools',
    //      route: 'service-scheduler',
    //      displayName: 'Service Scheduler',
    //      pdfEnabled: true,
    //      breadcrumbHelpEnabled: true,
    //      iconClass: 'fa fa-exchange',
    //      roles: undefined
    //    }
    //  ]
    //},
    //{
    //  id: 'user-activity',
    //  category: 'user-activity',
    //  default: true,
    //  defaultRoute: '/user-activity/overview',
    //  displayName: 'User Activity',
    //  displayInNav: true,
    //  iconClass: 'fa fa-users',
    //  roles: ['System Administrator', 'Corporate'],
    //  children: [
    //    {
    //      parent: '/user-activity',
    //      route: 'overview',
    //      displayName: 'Overview',
    //      pdfEnabled: false,
    //      iconClass: 'fa fa-users',
    //      breadcrumbHelpEnabled: true,
    //      roles: ['System Administrator', 'Corporate'],
    //    }]
    //},
    //{
    //  id: 'externalTools',
    //  category: 'externalTools',
    //  defaultRoute: '',
    //  displayName: 'External Tools',
    //  displayInNav: true,
    //  iconClass: 'fa fa-external-link',
    //  roles: undefined,
    //  externalTool: true,
    //  disableForDealerGroups: true,
    //  children: [
    //    {
    //      parent: '/externalTools',
    //      route: 'retailerTools',
    //      displayName: 'Retailer Tools',
    //      iconClass: 'fa fa-wrench',
    //      roles: undefined,
    //      breadcrumbHelpEnabled: true
    //    }
    //  ]
    //},
    //{
    //  id: 'resources',
    //  category: 'resources',
    //  defaultRoute: '/resources/resources',
    //  displayName: 'Resources',
    //  displayInNav: true,
    //  iconClass: 'fa fa-wrench',
    //  roles: undefined,
    //  disableForDealerGroups: true,
    //  children: [
    //    {
    //      parent: '/resources',
    //      route: 'resources',
    //      displayName: 'Resources',
    //      iconClass: 'fa fa-wrench',
    //      roles: undefined,
    //    }
    //  ]
    //},
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator', 'System Analyst'],
      children: [
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst']
        },
        //{
        //  parent: '/management',
        //  route: 'report-views-configuration',
        //  displayName: 'Report Views',
        //  iconClass: 'fa fa-code',
        //  roles: ['System Administrator', 'System Analyst'],
        //  children:
        //  [
        //    {
        //      id: 'report-views-configuration/create',
        //      parent: 'report-views-configuration',
        //      route: 'create',
        //      displayName: 'Create Report View',
        //      iconClass: 'fa fa-code',
        //      roles: ['System Administrator'],
        //    },
        //    {
        //      id: 'report-views-configuration/update',
        //      parent: 'report-views-configuration',
        //      route: 'update',
        //      displayName: 'Edit Report View',
        //      iconClass: 'fa fa-code',
        //      roles: ['System Administrator'],
        //    },
        //  ]
        //},
        //{
        //  parent: '/management',
        //  route: 'data-sets-configuration',
        //  displayName: 'Data Sets',
        //  iconClass: 'fa fa-code',
        //  roles: ['System Administrator', 'System Analyst'],
        //  children:
        //  [
        //    {
        //      id: 'data-sets-configuration/create',
        //      parent: 'data-sets-configuration',
        //      route: 'create',
        //      displayName: 'Create Data Set',
        //      iconClass: 'fa fa-code',
        //      roles: ['System Administrator'],
        //    },
        //    {
        //      id: 'data-sets-configuration/update',
        //      parent: 'data-sets-configuration',
        //      route: 'update',
        //      displayName: 'Edit Data Set',
        //      iconClass: 'fa fa-code',
        //      roles: ['System Administrator'],
        //    },
        //  ]
        //},
        //{
        //  parent: '/management',
        //  route: 'query-configuration',
        //  displayName: 'Queries',
        //  iconClass: 'fa fa-code',
        //  roles: ['System Administrator', 'System Analyst'],
        //  children:
        //  [
        //    {
        //      id: 'query-configuration/create',
        //      parent: 'query-configuration',
        //      route: 'create',
        //      displayName: 'Create Query',
        //      iconClass: 'fa fa-code',
        //      roles: ['System Administrator'],
        //    },
        //    {
        //      id: 'query-configuration/update',
        //      parent: 'query-configuration',
        //      route: 'update',
        //      displayName: 'Edit Query',
        //      iconClass: 'fa fa-code',
        //      roles: ['System Administrator'],
        //    },
        //  ]
        //},
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
          roles: ['System Administrator', 'System Analyst']
        }
      ]
    }
  ];
}
