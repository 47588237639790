import {
    IDefaultTableColumnConfig,
    ISalesSourceConfig,
    IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';
export class SalesSourceConfig implements ISalesSourceConfig {

    public useDefaultComparisonColumns = false;
    public metricDisplayModes = [
        { name: 'POP', value: 'MOM' },
        { name: 'YOY', value: 'YOY' }
    ];
    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.saleMetrics.totalSales.metricId,
                displayName: Enums.saleMetrics.totalSales.name,
                propertyName: Enums.saleMetrics.totalSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.saleMetrics.totalLeads.metricId,
                displayName: Enums.saleMetrics.totalLeads.name,
                propertyName: Enums.saleMetrics.totalLeads.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.saleMetrics.closeRate.metricId,
                displayName: Enums.saleMetrics.closeRate.name,
                propertyName: Enums.saleMetrics.closeRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
        ],

        defaultMetricOneId: Enums.saleMetrics.totalSales.metricId,
        defaultMetricTwoId: Enums.saleMetrics.totalLeads.metricId,
        metricOneColorHexCode: '#243882',
        metricTwoColorHexCode: '#292b33'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
      {
           show: true,
           header: 'Source',
           columnDef: 'entity',
           isMom: false,
           isYoy: false,
           metricFormatKey: constants.formatKeys.entityDisplayName,
           clickable: false
      },
      {
          show: true,
          header: 'Total Sales',
          columnDef: 'totalSales',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
          show: false,
          header: 'Prev. Period Total Sales',
          columnDef: 'totalSalesPreviousMonth',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
          show: false,
          header: 'Total Sales POP',
          columnDef: 'totalSalesMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
          show: false,
          header: 'Prev. Year Total Sales',
          columnDef: 'totalSalesPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
          show: false,
          header: 'Total Sales YOY',
          columnDef: 'totalSalesYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
          show: true,
          header: 'Total Leads',
          columnDef: 'totalLeads',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
          show: false,
          header: 'Prev. Period Total Leads',
          columnDef: 'totalLeadsPreviousMonth',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
          show: false,
          header: 'Total Leads POP',
          columnDef: 'totalLeadsMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
          show: false,
          header: 'Prev. Year Total Leads',
          columnDef: 'totalLeadsPreviousYear',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
          show: false,
          header: 'Total Leads YOY',
          columnDef: 'totalLeadsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Close Rate',
        columnDef: 'closeRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Prev. Period Close Rate',
        columnDef: 'closeRatePreviousMonth',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Close Rate POP',
        columnDef: 'closeRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Prev. Year Close Rate',
        columnDef: 'closeRatePreviousYear',
        isMom: false,
        isYoy: true,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: false,
        header: 'Close Rate YOY',
        columnDef: 'closeRateYOY',
        isMom: false,
        isYoy: true,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
    ];
}
