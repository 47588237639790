import { IDefaultTableColumnConfig, ILeadsSummaryConfig, IDualAxisLineGraphConfig, INameValuePair, IPropertyOverride, IPreviousDateRangeDisplay } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsSummaryConfig implements ILeadsSummaryConfig {

  public metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' },
    { name: 'Trend', value: 'Trend' }
  ];

  //public previousDateColumnDisplayOverrides = {
  //    previousMonthDisplay: 'Prev. Period',
  //    momDisplay: 'POP'
  //}

  public useDefaultComparisonColumns = true;

  public dealerLevelMetricOverrides: IPropertyOverride[] = [
    //{
    //  baseProperty: 'bestMatchSales',
    //  overrideProperty: 'sameDealerBestMatchSales'
    //},
    //{
    //  baseProperty: 'bestMatchCloseRate',
    //  overrideProperty: 'sameDealerBestMatchCloseRate'
    //},
    //{
    //  baseProperty: 'bestMatchCloseRate30',
    //  overrideProperty: 'sameDealerBestMatchCloseRate30'
    //},
    //{
    //  baseProperty: 'bestMatchCloseRate60',
    //  overrideProperty: 'sameDealerBestMatchCloseRate60'
    //},
    //{
    //  baseProperty: 'bestMatchCloseRate90',
    //  overrideProperty: 'sameDealerBestMatchCloseRate90'
    //},
    //{
    //  baseProperty: 'bestMatchCloseRate120',
    //  overrideProperty: 'sameDealerBestMatchCloseRate120'
    //},
  ];

  public graphConfig: IDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    availableMetrics: [
      {
        id: Enums.leadMetrics.leadVolume.metricId,
        displayName: 'Total Leads',
        propertyName: Enums.leadMetrics.leadVolume.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // BestMatchSales
      {
        id: Enums.leadMetrics.bestMatchSales.metricId,
        displayName: 'Lead Sales',
        propertyName: Enums.leadMetrics.bestMatchSales.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Appointments
      {
        id: Enums.leadMetrics.appointments.metricId,
        displayName: Enums.leadMetrics.appointments.name,
        propertyName: Enums.leadMetrics.appointments.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Hot Leads
      {
        id: Enums.leadMetrics.hotLeads.metricId,
        displayName: Enums.leadMetrics.hotLeads.name,
        propertyName: Enums.leadMetrics.hotLeads.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Total Responses
      {
        id: Enums.leadMetrics.timeToRespond.metricId,
        displayName: Enums.leadMetrics.timeToRespond.name,
        propertyName: Enums.leadMetrics.timeToRespond.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Response Rate
      {
        id: Enums.leadMetrics.responseRate.metricId,
        displayName: Enums.leadMetrics.responseRate.name,
        propertyName: Enums.leadMetrics.responseRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Response Rate < 4 Hrs
      {
        id: Enums.leadMetrics.responseRate240.metricId,
        displayName: 'Response Rate < 4 Hr',
        propertyName: Enums.leadMetrics.responseRate240.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Average Response Time
      {
        id: Enums.leadMetrics.averageResponseTime.metricId,
        displayName: Enums.leadMetrics.averageResponseTime.name,
        propertyName: Enums.leadMetrics.averageResponseTime.nameKey,
        metricFormatKey: constants.formatKeys.minutesTimeString
      },
      // Average Response Time < 48 Hrs
      {
        id: Enums.leadMetrics.averageResponseTime48Hour.metricId,
        displayName: Enums.leadMetrics.averageResponseTime48Hour.name,
        propertyName: Enums.leadMetrics.averageResponseTime48Hour.nameKey,
        metricFormatKey: constants.formatKeys.minutesTimeString
      },
      // 30 Day Close Rate
      {
        id: Enums.leadMetrics.closeRate30.metricId,
        displayName: Enums.leadMetrics.closeRate30.name,
        propertyName: 'bestMatchCloseRate30',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 60 Day Close Rate
      {
        id: Enums.leadMetrics.closeRate60.metricId,
        displayName: Enums.leadMetrics.closeRate60.name,
        propertyName: 'bestMatchCloseRate60',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 90 Day Close Rate
      {
        id: Enums.leadMetrics.closeRate90.metricId,
        displayName: Enums.leadMetrics.closeRate90.name,
        propertyName: 'bestMatchCloseRate90',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      //// 120 Day Close Rate
      //{
      //  id: Enums.leadMetrics.closeRate120.metricId,
      //  displayName: Enums.leadMetrics.closeRate120.name,
      //  propertyName: 'bestMatchCloseRate120',
      //  metricFormatKey: constants.formatKeys.percentageTwoDecimals
      //},
    ],
    defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
    defaultMetricTwoId: Enums.leadMetrics.closeRate30.metricId,
    metricOneColorHexCode: '#243882',
    metricTwoColorHexCode: '#292b33'
  };

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      hasComparisonMetrics: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      hasComparisonMetrics: false,
      metricFormatKey: constants.formatKeys.entityDisplayName
    },
    // Total Leads
    {
      show: true,
      header: 'Total Leads',
      columnDef: 'leadVolume',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Lead Sales
    {
      show: true,
      header: 'Lead Sales',
      columnDef: 'bestMatchSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Appointments
    {
      show: true,
      header: 'Appointments',
      columnDef: 'appointments',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Hot Leads
    {
      show: true,
      header: 'Hot Leads',
      columnDef: 'hotLeads',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Total Responses
    {
      show: true,
      header: 'Total Responses',
      columnDef: 'timeToRespond',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Response Rate
    {
      show: true,
      header: 'Response Rate',
      columnDef: 'responseRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Response Rate < 4 Hrs
    {
      show: true,
      header: 'Response Rate < 4 Hr',
      columnDef: 'responseRate240',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Average Response Time
    {
      show: true,
      header: 'Average Response Time',
      columnDef: 'averageResponseTime',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.minutesTimeString
    },
    // Average Response Time < 48 Hrs
    {
      show: true,
      header: 'Average Response Time (48 Hr)',
      columnDef: 'averageResponseTime48Hour',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.minutesTimeString
    },
    // Close Rate 30 Days
    {
      show: true,
      header: '30 Day Close Rate',
      columnDef: 'bestMatchCloseRate30',
      hasComparisonMetrics: true,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Close Rate 60 Days
    {
      show: true,
      header: '60 Day Close Rate',
      columnDef: 'bestMatchCloseRate60',
      hasComparisonMetrics: true,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Close Rate 90 Days
    {
      show: true,
      header: '90 Day Close Rate',
      columnDef: 'bestMatchCloseRate90',
      hasComparisonMetrics: true,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    //// Close Rate 120 Days
    //{
    //  show: true,
    //  header: '120 Day Close Rate',
    //  columnDef: 'bestMatchCloseRate120',
    //  hasComparisonMetrics: true,
    //  comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
    //  metricFormatKey: constants.formatKeys.percentageTwoDecimals
    //},
  ];

  public trendTableMetrics: INameValuePair[] = [
    { name: 'Total Leads', value: 'leadVolume' },
    { name: 'Lead Sales', value: 'bestMatchSales' },
    { name: 'Appointments', value: 'appointments' },
    { name: 'Hot Leads', value: 'hotLeads' },
    { name: 'Response Rate', value: 'responseRate' },
    { name: 'Response Rate < 4 Hr', value: 'responseRate240' },
    { name: 'Average Response Time', value: 'averageResponseTime' },
    { name: 'Average Response Time < 48 Hrs', value: 'averageResponseTime48Hour' },
    { name: '30 Day Close Rate', value: 'bestMatchCloseRate30' },
    { name: '60 Day Close Rate', value: 'bestMatchCloseRate60' },
    { name: '90 Day Close Rate', value: 'bestMatchCloseRate90' },
    //{ name: '120 Day Close Rate', value: 'bestMatchCloseRate120' },
  ];
}
