import { IOrgLookupType } from '../../_shared/enums/enums';
import { IOrgConfig } from '../../_shared/models/models-index';

export class OrgConfig implements IOrgConfig {

  orgLookupTypes: Record<string, IOrgLookupType> = {
    'bennington': { name: 'Bennington', orgLookupTypeId: 1, default: true, orgEntityType: 'national' },
    'hurricane': { name: 'Hurricane', orgLookupTypeId: 2, default: false, orgEntityType: 'national' },
    'godfrey': { name: 'Godfrey', orgLookupTypeId: 3, default: false, orgEntityType: 'national' },
    'rinker': { name: 'Rinker', orgLookupTypeId: 4, default: false, orgEntityType: 'national' },
    'striper': { name: 'Striper', orgLookupTypeId: 5, default: false, orgEntityType: 'national' },
    'larson': { name: 'Larson', orgLookupTypeId: 6, default: false, orgEntityType: 'national' }
  };

  public nationalOrgLookupTypeIds: number[] = [1,2,3,4,5,6];
  public lmaOrgLookupTypeIds: number[] = [];
  public virtual20OrgLookupTypeIds: number[] = [];
  public dealerGroupOrgLookupTypeIds: number[] = [];

  orgEntityType: Map<string, string> = new Map([
    ['orgcode2', 'orgcode2'],
    ['orgcode1', 'orgcode1'],
    ['dealer', 'dealer'],
  ]);

  entityTypeName: Map<string, string> = new Map([
    ['orgcode2', 'BC'],
    ['orgcode1', 'District'],
    ['dealer', 'Dealer'],
  ]);
}
