import { IDefaultTableColumnConfig, ISourceSummaryConfig, IDualAxisLineGraphConfig, IPropertyOverride } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class SourceSummaryConfig implements ISourceSummaryConfig {

  public reportTitle = 'Lead Source Performance';

  metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' }
  ];

  //public previousDateColumnDisplayOverrides = {
  //    previousMonthDisplay: 'Prev. Period',
  //    momDisplay: 'POP'
  //}

  public dealerLevelMetricOverrides: IPropertyOverride[] = [
    //{
    //  baseProperty: 'householdSales',
    //  overrideProperty: 'sameDealerHouseholdSales'
    //},
    //{
    //  baseProperty: 'householdCloseRate',
    //  overrideProperty: 'sameDealerHouseholdCloseRate'
    //},
    //{
    //  baseProperty: 'householdCloseRate30',
    //  overrideProperty: 'sameDealerHouseholdCloseRate30'
    //},
    //{
    //  baseProperty: 'householdCloseRate60',
    //  overrideProperty: 'sameDealerHouseholdCloseRate60'
    //},
    //{
    //  baseProperty: 'householdCloseRate90',
    //  overrideProperty: 'sameDealerHouseholdCloseRate90'
    //},
    //{
    //  baseProperty: 'householdCloseRate120',
    //  overrideProperty: 'sameDealerHouseholdCloseRate120'
    //},
    //{
    //  baseProperty: 'bestMatchSales',
    //  overrideProperty: 'sameDealerBestMatchSales'
    //},
    //{
    //  baseProperty: 'bestMatchCloseRate',
    //  overrideProperty: 'sameDealerBestMatchCloseRate'
    //},
    //{
    //  baseProperty: 'bestMatchCloseRate30',
    //  overrideProperty: 'sameDealerBestMatchCloseRate30'
    //},
    //{
    //  baseProperty: 'bestMatchCloseRate60',
    //  overrideProperty: 'sameDealerBestMatchCloseRate60'
    //},
    //{
    //  baseProperty: 'bestMatchCloseRate90',
    //  overrideProperty: 'sameDealerBestMatchCloseRate90'
    //},
    //{
    //  baseProperty: 'bestMatchCloseRate120',
    //  overrideProperty: 'sameDealerBestMatchCloseRate120'
    //}
  ];

  useDefaultComparisonColumns = true;

  public graphConfig: IDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    availableMetrics: [
      // Leads
      {
        id: Enums.leadMetrics.leadVolume.metricId,
        displayName: 'Total Leads',
        propertyName: Enums.leadMetrics.leadVolume.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Unique Leads
      {
        id: Enums.leadMetrics.uniqueLeads.metricId,
        displayName: 'Unique Lead Volume',
        propertyName: Enums.leadMetrics.uniqueLeads.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Sales
      {
        id: Enums.leadMetrics.leadSales.metricId,
        displayName: 'Sales',
        propertyName: 'householdSales',
        metricFormatKey: constants.formatKeys.localeString
      },
      // Response Rate
      {
        id: Enums.leadMetrics.responseRate.metricId,
        displayName: 'Response Rate',
        propertyName: 'responseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Close Rate
      {
        id: Enums.leadMetrics.conversionRate.metricId,
        displayName: 'Close Rate',
        propertyName: 'bestMatchCloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Attributed Close Rate (Household Close Rate)
      {
        id: Enums.leadMetrics.householdCloseRate.metricId,
        displayName: 'Attributed Close Rate',
        propertyName: Enums.leadMetrics.householdCloseRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Daily Volume
      {
        id: Enums.leadMetrics.averageDailyVolume.metricId,
        displayName: 'Avg. Daily Volume',
        propertyName: Enums.leadMetrics.averageDailyVolume.nameKey,
        metricFormatKey: constants.formatKeys.roundToTenth
      },

    ],
    defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
    defaultMetricTwoId: Enums.leadMetrics.leadSales.metricId,
    metricOneColorHexCode: '#243882',
    metricTwoColorHexCode: '#292b33'
  }

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    // Leads
    {
      show: true,
      header: 'Total Leads',
      columnDef: 'leadVolume',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Unique Leads
    {
      show: true,
      header: 'Unique Lead Volume',
      columnDef: 'uniqueLeads',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Sales
    {
      show: true,
      header: 'Sales',
      columnDef: 'bestMatchSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Response Rate
    {
      show: true,
      header: 'Response Rate',
      columnDef: 'responseRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Close Rate
    {
      show: true,
      header: 'Close Rate',
      columnDef: 'bestMatchCloseRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Attributed Close Rate
    {
      show: true,
      header: 'Attributed Close Rate',
      columnDef: 'householdCloseRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Avg Daily Volume
    {
      show: true,
      header: 'Avg. Daily Volume',
      columnDef: 'averageDailyVolume',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.roundToTenth,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
  ];
}
